import { ref, computed, onUnmounted } from 'vue';
import { getOrCreateProcedure } from 'o365-modules';
import { getDataObjectById } from 'o365-dataobject';
import { useDataObjectEventListener } from 'o365-vue-utils';
import FunctionBroadcastChannel from 'o365.modules.FunctionBroadcastChannel.ts';
import { $t } from 'o365-utils';
import type { DataObject, DataItemModel } from 'o365-dataobject';
import type { ComputedRef, Ref } from "vue";
import { isMobile } from "o365.GlobalState.ts";

export type CheckItemProps = {
    dataObject: DataObject;
    itemId: Number;
    stepId?: Number;
    sectionId?: Number;
    promotedProcessId?: Number;
};

enum CheckItemStatus {
    Checked = 0,
    Unchecked = -1,
    NotApplicable = 1
};

const dsCheckItems_ItemLanguages = getDataObjectById("dsCheckItems_ItemLanguages");
const dsCheckItems_ScopeItem = getDataObjectById("dsCheckItems_ScopeItem");
const dsCheckItems_Statistics = getDataObjectById("dsCheckItems_Statistics"); // NOTE: This data object switches between two different views, depending on whether Step or Section modes are active.
const dsCheckItems_ItemsUrls = getDataObjectById("dsCheckItems_ItemsUrls");
const dsCheckItems_ConnectedScopeItems = getDataObjectById("dsCheckItems_ConnectedScopeItems");
const dsCheckItems_Attachments = getDataObjectById("dsCheckItems_Attachments");
const dsCheckItems_ItemsUrlsAll = getDataObjectById("dsCheckItems_ItemsUrlsAll");
const dsCheckItems_Items = getDataObjectById("dsCheckItems_Items");

export function useCheckItems(props: CheckItemProps) {
    // Refs
    const isOpeningDialog: Ref<boolean> = ref(false);
    const isExecutingProc: Ref<boolean> = ref(false);
    const isBatchUpdatingStatus: Ref<boolean> = ref(false);
    const isSettingChecklist: Ref<boolean> = ref(false);
    const currentlyUpdatingCheckStatusId: Ref<number | null> = ref(null);

    // Stored Procedures
    const astp_Scope_ItemsDeleteFile = getOrCreateProcedure({ id: "astp_Scope_ItemsDeleteFile" + crypto.randomUUID(), procedureName: "astp_Scope_ItemsDeleteFile" });
    const astp_Scope_ItemsSetAllCheckItemsStatus = getOrCreateProcedure({ id: "astp_Scope_ItemsSetAllCheckItemsStatus" + crypto.randomUUID(), procedureName: "astp_Scope_ItemsSetAllCheckItemsStatus" });
    const astp_Scope_ItemsCheckItemsRemoveItemConnection = getOrCreateProcedure({ id: "astp_Scope_ItemsCheckItemsRemoveItemConnection" + crypto.randomUUID(), procedureName: "astp_Scope_ItemsCheckItemsRemoveItemConnection" });
    const astp_Scope_ItemSectionSetChecklist = getOrCreateProcedure({ id: "astp_Scope_ItemSectionSetChecklist" + crypto.randomUUID(), procedureName: "astp_Scope_ItemSectionSetChecklist" });

    // Computed values
    const filesForItem = computed(() => (checkItemId: number) => dsCheckItems_Attachments.data.filter(x => x.CheckItem_ID == checkItemId));
    const scopeItemsForCheckItem = computed(() => (checkItemId: number) => dsCheckItems_ConnectedScopeItems.data.filter(x => x.CheckItem_ID == checkItemId));
    const urlsForItem = computed(() => groupBy((url: { CheckItem_ID: number }) => url.CheckItem_ID, dsCheckItems_ItemsUrlsAll.data));
    const isBaseLanguage = computed(() => {
        if (!dsCheckItems_ItemLanguages.current) return true;
        if (!props.dataObject?.current?.BaseLanguage) return true;
        return props.dataObject.current.BaseLanguage === dsCheckItems_ItemLanguages.current.TranslateTo;
    });
    const sectionMode: ComputedRef<boolean> = computed(() => props.sectionId != null);
    const stepsMode = computed(() => props.stepId != null);
    const statistics = computed(() => dsCheckItems_Statistics.data.find(s => {
        // If we are displaying a Checklist related to a Step, we use Step_ID in check, otherwise Section_ID. See setStatisticsView() function.
        if (dsCheckItems_Statistics.fields.fieldExists("Section_ID")) {
            return s.Section_ID == props.sectionId;
        } else if (dsCheckItems_Statistics.fields.fieldExists("Step_ID")) {
            return s.Step_ID == props.stepId;
        }
        return null;
    }));
    const checklistName: ComputedRef<string | null> = computed(() => {
        if (props.dataObject.state.isLoading) {
            return null;
        } else if (props.dataObject.data.length > 0 && props.dataObject.data[0].ChecklistName) {
            return props.dataObject.data[0].ChecklistName;
        } else {
            return $t("User Defined");
        }
    });
    const checklistRevision: ComputedRef<string | null> = computed(() => {
        if (props.dataObject.state.isLoading) {
            return null;
        } else if (props.dataObject.data.length > 0 && props.dataObject.data[0].ChecklistRevision) {
            return props.dataObject.data[0].ChecklistRevision;
        } else {
            return null;
        }
    });
    const checklistProcedures = computed(() => {
        const itemsWithProcedure = props.dataObject.data.filter((i: any) => !!i.ChecklistProcedure);
        return distinctBy(itemsWithProcedure, (i: any) => i.ChecklistName);
    });

    // Event listeners
    useDataObjectEventListener(props.dataObject, "AfterSave", onCheckItemsCreatedOrDeleted);
    useDataObjectEventListener(props.dataObject, "AfterDelete", onCheckItemsCreatedOrDeleted);
    useDataObjectEventListener(props.dataObject, "BeforeSave", onBeforeCheckItemsSave);
    useDataObjectEventListener(dsCheckItems_ItemsUrls, "AfterSave", () => dsCheckItems_ItemsUrlsAll.load());
    useDataObjectEventListener(dsCheckItems_ItemsUrls, "AfterDelete", () => dsCheckItems_ItemsUrlsAll.load());

    // Methods
    function initComposable() {
        setStatisticsView();

        dsCheckItems_ScopeItem.recordSource.whereClause = `ID = ${props.itemId}`;
        dsCheckItems_ScopeItem.load();
    }

    function onCheckItemsCreatedOrDeleted() {
        if (props.dataObject.data.length === 0) {
            // If we just deleted a record and it is the only record in the checklist, then we reload the check items data object used in Section component        
            dsCheckItems_Items.load();
        }
        dsCheckItems_Statistics.load();
    }

    function onBeforeCheckItemsSave(opts: any, row: DataItemModel) {
        if (sectionMode.value && !row.Section_ID && props.sectionId) {
            opts.values["Section_ID"] = props.sectionId;
        } else if (stepsMode.value && !row.Step_ID && props.stepId) {
            opts.values["Step_ID"] = props.stepId;
        }
    }

    function setStatisticsView() {
        if (sectionMode.value) {
            dsCheckItems_Statistics.viewName = "aviw_Scope_ItemsSectionsCheckItemsStatistics";
            dsCheckItems_Statistics.fields.addFieldIfNotExists({ name: "Section_ID", type: "number" });
        } else if (stepsMode.value) {
            dsCheckItems_Statistics.viewName = "aviw_Scope_ItemsStepsCheckItemsStatistics";
            dsCheckItems_Statistics.fields.addFieldIfNotExists({ name: "Step_ID", type: "number" });
        }
    }

    function changeChecklistLanguage(index: number) {
        dsCheckItems_ItemLanguages.setCurrentIndex(index);
    }

    async function batchUpdateCheckedStatus(setToStatus: "unchecked" | "checked" | "N/A") {
        isBatchUpdatingStatus.value = true;
        const shouldForceAll = setToStatus === "unchecked";

        let status = null;
        if (setToStatus === "unchecked") {
            status = -1;
        } else if (setToStatus === "checked") {
            status = 0;
        } else if (setToStatus === "N/A") {
            status = 1;
        }

        await astp_Scope_ItemsSetAllCheckItemsStatus.execute({
            Item_ID: dsCheckItems_ScopeItem.current?.ID,
            Section_ID: sectionMode.value ? props.sectionId : null,
            Step_ID: stepsMode.value ? props.stepId : null,
            Status: status,
            ForceAll: shouldForceAll
        }).then(() => {
            props.dataObject.load();
            dsCheckItems_Statistics.load();
        }).finally(() => {
            isBatchUpdatingStatus.value = false;
        });
    }

    function onBeforeFileUpload(checkItemId: number) {
        return { "CheckItem_ID": checkItemId };
    }

    function groupBy(keyFn: Function, xs: any[]) {
        return xs.reduce((acc, x) => {
            const key = keyFn(x);
            if (key in acc) {
                acc[key].push(x);
                return acc;
            } else {
                return { ...acc, [key]: [x] };
            }
        }, {});
    }

    function distinctBy(data: any, f: Function) {
        const set = new Set();
        return data.filter((x: any) => {
            const value = f(x);
            if (set.has(value)) {
                return false;
            }
            set.add(value);
            return true;
        });
    }

    async function deleteFile(id: number) {
        isExecutingProc.value = true;
        await astp_Scope_ItemsDeleteFile.execute({ ID: id })
            .then(() => {
                props.dataObject.refreshRow();
                dsCheckItems_Attachments.load();
            })
            .finally(() => (isExecutingProc.value = false));
    }

    async function removeScopeItemConnection(itemId: number) {
        if (isMobile.value) {
            if (!confirm($t("Are you sure you want to remove the Scope Item connection?"))) {
                return;
            }
        }
        isExecutingProc.value = true;
        await astp_Scope_ItemsCheckItemsRemoveItemConnection.execute({ Item_ID: itemId })
            .then(() => dsCheckItems_ConnectedScopeItems.load())
            .finally(() => (isExecutingProc.value = false));
    }

    async function setStatus(row: DataItemModel, status: CheckItemStatus) {
        row.Status = status;
        currentlyUpdatingCheckStatusId.value = row.ID;
        try {
            await row.save();
        } finally {
            currentlyUpdatingCheckStatusId.value = null;
        }
    }

    // Pass undefined or null to clear Checklist
    async function setChecklist(checklistId: number | undefined | null) {
        isSettingChecklist.value = true;
        try {
            if (sectionMode.value) {
                await astp_Scope_ItemSectionSetChecklist.execute({ Item_ID: props.itemId, Section_ID: props.sectionId, Checklist_ID: checklistId });
            } else {
                // TODO: Implement?
            }
            await props.dataObject.load();
        } finally {
            isSettingChecklist.value = false;
        }
    }

    function createNewScopeItem(checkItemId: number) {
        // Quick and simple. Needs to be improved with support for objects etc.
        const url = `/nt/scope-workflow-new?Context=${dsCheckItems_ScopeItem.current?.OrgUnit_ID}&parentitem_id=${dsCheckItems_ScopeItem.current?.ID}&checkitem_id=${checkItemId}${props.promotedProcessId ? `&process-id=${props.promotedProcessId}` : ''}`;
        if (isMobile.value) {
            window.location.assign(url);
        } else {
            window.open(url, "_blank");
        }
    }

    // Listen for broadcasts from other tab to see if WF has been created
    const broadcastChannel = new FunctionBroadcastChannel({
        id: "scope-workflow-new",
        functions: {
            'onWorkflowCreated': () => {
                dsCheckItems_ConnectedScopeItems.load();
                return Promise.resolve(undefined);
            }
        }
    });

    onUnmounted(() => {
        broadcastChannel?.close(); // Unsubscribe when component is unmounted
    });

    initComposable();

    return {
        isOpeningDialog,
        isExecutingProc,
        isBatchUpdatingStatus,
        isSettingChecklist,
        filesForItem,
        scopeItemsForCheckItem,
        urlsForItem,
        isBaseLanguage,
        sectionMode,
        stepsMode,
        statistics,
        changeChecklistLanguage,
        batchUpdateCheckedStatus,
        onBeforeFileUpload,
        deleteFile,
        removeScopeItemConnection,
        setStatus,
        checklistName,
        checklistRevision,
        currentlyUpdatingCheckStatusId,
        setChecklist,
        checklistProcedures,
        createNewScopeItem
    };
}